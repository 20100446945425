import { styled, Box } from "@material-ui/core";
import { rem } from "polished";

const StyledFancyHr = styled(Box)(({ theme }) => ({
  padding: `${rem(theme.spacing(2))}`,
}));

const StyledGreenBox = styled(Box)(({ theme, center }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  width: `${rem(48)}`,
  height: `${rem(8)}`,
  margin: `${center ? "0 auto" : "initial"}`,
}));

export { StyledFancyHr, StyledGreenBox };
