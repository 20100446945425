import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";

import { StyledTutoring } from "./Tutoring.styled";

import HeroTutoring from "../../images/hero-tutoring.jpg";

const Tutoring = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledTutoring>
      <Box
        component="img"
        src={HeroTutoring}
        alt="children learning"
        sx={{ width: `100%` }}
      />
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Tutoring Program
              </Typography>
            </Box>
            <FancyHr center />

            <Typography paragraph align="center">
              The after school tutoring program serves K-12 students in the
              Greater Leavenworth Area. The program is staffed by volunteer
              teachers, military personnel, and community members. Parents must
              enroll students on a first come, first serve waiting list. All
              students are welcome.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#EFEFEF` }}
      >
        <Grid container>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent={isSmall ? "center" : "flex-start"}
                >
                  <Typography variant="h4">Schedule</Typography>
                </Box>
                <FancyHr />
                <ul>
                  <li>
                    <Typography paragraph>
                      Tutoring is available from 4 to 5:30
                    </Typography>
                  </li>
                  <li>
                    <Typography paragraph>Grades K-12</Typography>
                  </li>
                  <li>
                    <Typography paragraph>
                      For students in the greater Leavenworth Area.
                    </Typography>
                  </li>
                  <li>
                    <Typography paragraph>
                      Spaces are on a first come first serve basis. With
                      priority going to returning students.
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Box>
    </StyledTutoring>
  );
};

Tutoring.propTypes = {
  children: PropTypes.node,
};

export default Tutoring;
