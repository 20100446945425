import React from "react";
import PropTypes from "prop-types";

import { StyledFancyHr, StyledGreenBox } from "./FancyHr.styled";

const FancyHr = ({ center }) => {
  return (
    <StyledFancyHr>
      <StyledGreenBox center={center} />
    </StyledFancyHr>
  );
};

FancyHr.defaultProps = {
  center: false,
};

FancyHr.propTypes = {
  center: PropTypes.bool,
};

export default FancyHr;
